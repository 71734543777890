"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationError64 = void 0;
/* eslint-disable id-denylist */
exports.ConfirmationError64 = {
    v: '5.6.10',
    fr: 60,
    ip: 0,
    op: 91,
    w: 64,
    h: 64,
    nm: 'Error 40',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 3,
            nm: 'Controller',
            sr: 1,
            ks: {
                o: { a: 0, k: 0, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [32.438, 31.125, 0], ix: 2 },
                a: { a: 0, k: [60, 60, 0], ix: 1 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.593, 0.593, 0.2], y: [1, 1, 1] },
                            o: { x: [0.998, 0.998, 0.5], y: [0, 0, 0] },
                            t: 30,
                            s: [100, 100, 100],
                        },
                        {
                            i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                            o: { x: [0.719, 0.719, 0.5], y: [0, 0, 0] },
                            t: 49,
                            s: [112, 112, 100],
                        },
                        { t: 63, s: [100, 100, 100] },
                    ],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 30,
            op: 210,
            st: -90,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Dot',
            parent: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [59.562, 69.875, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ty: 'rc',
                            d: 1,
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.2, 0.2], y: [1, 1] },
                                        o: { x: [0.5, 0.5], y: [0, 0] },
                                        t: 41,
                                        s: [0, 0],
                                    },
                                    { t: 48, s: [4, 4] },
                                ],
                                ix: 2,
                            },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [0.75686275959, 0.0941176489, 0.149019613862, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: '.colorBrandedErrorFill',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                            cl: 'colorBrandedErrorFill',
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Rectangle 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 41,
            op: 180,
            st: 11,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Bar',
            parent: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    s: true,
                    x: { a: 0, k: 59.533, ix: 3 },
                    y: {
                        a: 1,
                        k: [
                            { i: { x: [0.34], y: [1] }, o: { x: [0.88], y: [0] }, t: 30, s: [52.875] },
                            { i: { x: [0], y: [1] }, o: { x: [0.172], y: [0] }, t: 48, s: [57.875] },
                            { i: { x: [0.697], y: [0.724] }, o: { x: [0.157], y: [0] }, t: 55, s: [56.875] },
                            { i: { x: [0.778], y: [1] }, o: { x: [0.428], y: [0.618] }, t: 67, s: [56.792] },
                            { t: 69, s: [56.783] },
                        ],
                        ix: 4,
                    },
                },
                a: { a: 0, k: [0.13, -5.083, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0.132, -12.666],
                                        [0.152, 1.963],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.2], y: [1] }, o: { x: [0.5], y: [0] }, t: 30, s: [0] },
                                    { t: 50, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.756862745098, 0.094117647059, 0.149019607843, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 4, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: '.colorBrandedErrorStroke',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                            cl: 'colorBrandedErrorStroke',
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 30,
            op: 180,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Fill circle',
            parent: 1,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                    a: 1,
                    k: [
                        { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                        { t: 90, s: [418] },
                    ],
                    ix: 10,
                },
                p: { a: 0, k: [59.562, 60.875, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [40, 40], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    { i: { x: [0.2], y: [1] }, o: { x: [0.5], y: [0] }, t: 0, s: [0] },
                                    { t: 30, s: [0] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    { i: { x: [0.2], y: [1] }, o: { x: [0.5], y: [0] }, t: 0, s: [16] },
                                    { t: 30, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.756862745098, 0.094117647059, 0.149019607843, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 4, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: '.colorBrandedErrorStroke',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                            cl: 'colorBrandedErrorStroke',
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 180,
            st: -90,
            bm: 0,
        },
    ],
    markers: [],
};
