"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.injectResources = void 0;
function createLinkElement(href) {
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = href;
    return linkElement;
}
function createScriptElement(src) {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = src;
    return scriptElement;
}
function onResourceLoad(element) {
    return new Promise((resolve, reject) => {
        element.addEventListener('load', () => {
            resolve();
        });
        element.addEventListener('error', () => {
            reject(new Error('Unable to load resource.'));
        });
    });
}
async function injectResources(options) {
    const promises = [];
    if (options.jsToInject) {
        promises.push(options.jsToInject.reduce(async (_promise, src) => {
            const scriptElement = createScriptElement(src);
            const loadPromise = onResourceLoad(scriptElement);
            document.body.appendChild(scriptElement);
            return await loadPromise;
        }, Promise.resolve()));
    }
    if (options.cssToInject) {
        promises.push(options.cssToInject.reduce(async (_promise, src) => {
            const linkElement = createLinkElement(src);
            const loadPromise = onResourceLoad(linkElement);
            document.head.appendChild(linkElement);
            return await loadPromise;
        }, Promise.resolve()));
    }
    if (options.injectLottie) {
        window.lottie = {
            loadAnimation: (config) => {
                config.container.innerHTML = '<div>A Fake lottie animation</div>';
                return {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    setSpeed: (speed) => {
                        // this is just a mock so the iris-lottie-animation component won't throw an exception
                    },
                };
            },
        };
    }
    await Promise.all(promises);
}
exports.injectResources = injectResources;
