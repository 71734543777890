import { render, staticRenderFns } from "./UserIdentifyingField.vue?vue&type=template&id=c778b9ec&scoped=true&"
import script from "./UserIdentifyingField.vue?vue&type=script&lang=ts&"
export * from "./UserIdentifyingField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c778b9ec",
  null
  
)

export default component.exports