"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeType = void 0;
var ChallengeType;
(function (ChallengeType) {
    ChallengeType["ChangePassword"] = "ChangePassword";
    ChallengeType["CheckSyncResult"] = "CheckSyncResult";
    ChallengeType["ForgotUsernameChooseScenario"] = "ForgotUsernameChooseScenario";
    ChallengeType["ForgotPasswordChooseScenario"] = "ForgotPasswordChooseScenario";
    ChallengeType["ChooseRegistrationType"] = "ChooseRegistrationType";
    ChallengeType["ForgotUsernameConfirmIdentity"] = "ForgotUsernameConfirmIdentity";
    ChallengeType["CreateUsername"] = "CreateUsername";
    ChallengeType["Done"] = "Done";
    ChallengeType["Empty"] = "Empty";
    ChallengeType["OneTimeCode"] = "OneTimeCode";
    ChallengeType["OneTimeCodeDestination"] = "OneTimeCodeDestination";
    ChallengeType["QuestionsAndAnswers"] = "QuestionsAndAnswers";
    ChallengeType["RegistrationComplete"] = "RegistrationComplete";
    ChallengeType["StartForgotPassword"] = "StartForgotPassword";
    ChallengeType["StartForgotUsername"] = "StartForgotUsername";
    ChallengeType["StartRegistration"] = "StartRegistration";
    ChallengeType["StartSync"] = "StartSync";
    ChallengeType["StepUp"] = "StepUp";
    ChallengeType["TemporaryPassword"] = "TemporaryPassword";
    ChallengeType["UsernameAndPassword"] = "UsernameAndPassword";
    ChallengeType["UsernameRecovered"] = "UsernameRecovered";
    ChallengeType["Tethered"] = "Tethered";
    ChallengeType["ConvertedUser"] = "ConvertedUser";
    ChallengeType["ForgotPasswordDisclosure"] = "ForgotPasswordDisclosure";
    ChallengeType["ForgotPasswordConfirmIdentity"] = "ForgotPasswordConfirmIdentity";
    ChallengeType["StepUpForgot"] = "StepUpForgot";
    ChallengeType["OneTimeCodeForgotContainer"] = "OneTimeCodeForgotContainer";
    ChallengeType["CheckSyncResultForgot"] = "CheckSyncResultForgot";
    ChallengeType["RegistrationDisclosure"] = "RegistrationDisclosure";
    ChallengeType["ConfirmIdentity"] = "ConfirmIdentity";
    ChallengeType["CreateUser"] = "CreateUser";
    ChallengeType["UpdateBusiness"] = "UpdateBusiness";
})(ChallengeType = exports.ChallengeType || (exports.ChallengeType = {}));
