"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeStatus = void 0;
var ChallengeStatus;
(function (ChallengeStatus) {
    ChallengeStatus["DeviceNameAlreadyExists"] = "DeviceNameAlreadyExists";
    ChallengeStatus["Disabled"] = "Disabled";
    ChallengeStatus["InvalidCaptchaResponse"] = "InvalidCaptchaResponse";
    ChallengeStatus["InvalidCredentials"] = "InvalidCredentials";
    ChallengeStatus["InvalidOneTimeCode"] = "InvalidOneTimeCode";
    ChallengeStatus["LockedOut"] = "LockedOut";
    ChallengeStatus["SyncError"] = "SyncError";
    ChallengeStatus["Success"] = "Success";
    ChallengeStatus["Unknown"] = "Unknown";
    ChallengeStatus["SessionExpired"] = "SessionExpired";
    ChallengeStatus["LockedOutinExternalIdentityProvider"] = "LockedOutinExternalIdentityProvider";
    ChallengeStatus["UserNotFound"] = "UserNotFound";
    ChallengeStatus["MaximumAttemptsReached"] = "MaximumAttemptsReached";
    ChallengeStatus["PdfCodeInvalid"] = "PdfCodeInvalid";
    ChallengeStatus["OTPExpired"] = "OTPExpired";
    ChallengeStatus["BotDetected"] = "BotDetected";
})(ChallengeStatus = exports.ChallengeStatus || (exports.ChallengeStatus = {}));
