"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function fromEntries(iterable) {
    return Array.from(iterable).reduce((obj, entry) => {
        const key = entry[0];
        const val = entry[1];
        obj[key] = val;
        return obj;
    }, {});
}
exports.default = fromEntries;
