"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrustMachineSecretProvider = void 0;
class EntrustMachineSecretProvider {
    initialize(username) {
        machineSecret
            .setDebugOn(false)
            .setStorageTypeExclusions('flash')
            .setMachineNonceName(this.getMachineNonceName(username))
            .setSequenceNonceName(this.getSequenceNonceName(username));
    }
    read() {
        const map = {};
        map.MachineNonce = machineSecret.fetchMachineNonce();
        map.SequenceNonce = machineSecret.fetchSequenceNonce();
        map.DeviceFingerprint = this.cleanUpFingerprint(machineSecret.getDeviceFingerprint());
        return map;
    }
    write(map) {
        machineSecret.storeMachineNonce(map.MachineNonce);
        machineSecret.storeSequenceNonce(map.SequenceNonce);
    }
    getRealm() {
        return 'https://' + window.location.hostname + '/';
    }
    getMachineNonceName(username) {
        const prefix = 'ob_mn';
        return this.getHash(this.getRealm(), username, prefix);
    }
    getSequenceNonceName(username) {
        const prefix = 'ob_sn';
        return this.getHash(this.getRealm(), username, prefix);
    }
    getHash(url, username, prefix) {
        const input = prefix + username.toLowerCase();
        const keyArray = CryptoJS.enc.Utf8.parse(url);
        const inputArray = CryptoJS.enc.Utf8.parse(input);
        const hash = CryptoJS.HmacSHA1(inputArray, keyArray);
        const hashInHex = CryptoJS.enc.Hex.stringify(hash);
        return hashInHex.toUpperCase();
    }
    cleanUpFingerprint(fingerPrintStr) {
        /* tslint:disable */
        // Here is a sample fingerprint string from Android: {"platform":"web","version":"2.0.0","attributes":{"browserName":"Chrome","browserVersion":"74.0.3729.112","osName":"Android","osVersion":"5.1.1","plugins":[],"platform":"Linux aarch64","appVersion":"5.0 (Linux; Android 5.1.1; SAMSUNG-SM-G920A Build/LMY47X; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/74.0.3729.112 Mobile Safari/537.36 NATIVEAPP NATIVEMENU NATIVEPIN","cssVendorPrefix":"webkit","cookiesEnabled":true,"javaEnabled":false,"flashEnabled":false,"flashVersion":"0.0.0","language":"en-US","doNotTrack":"unknown","timezoneOffset":-240,"width":360,"height":640,"availWidth":360,"availHeight":640,"colorDepth":24,"localStorage":true,"sessionStorage":true,"indexedDB":true,"fonts":[25,43,48,63,64,65,72,127,128,148,198,199,200,229,236,324,326,369,447,455,456,471,476,488,489],"canvas":"a27f593234a369488586c2b56655c33bbf9fbc37a22e697f884a13c7e176e576","webGL":["b1b8461e148f9d45a438852f32b732093ff4c1d95535cf92b72811d13fc22e08","ANGLE_instanced_arrays;EXT_blend_minmax;EXT_sRGB;OES_element_index_uint;OES_standard_derivatives;OES_vertex_array_object;WEBGL_compressed_texture_astc;WEBGL_compressed_texture_etc1;WEBGL_debug_renderer_info;WEBGL_debug_shaders;WEBGL_depth_texture;WEBKIT_WEBGL_depth_texture;WEBGL_lose_context;WEBKIT_WEBGL_lose_context","(1 x 4095.9375)","(1 x 1024)",8,true,8,24,8,null,48,4096,1024,8192,16,4096,15,16,16,1024,"(8192 x 8192)",8,"WebKit WebGL","WebGL GLSL ES 1.0 (OpenGL ES GLSL ES 1.0 Chromium)",0,"WebKit","WebGL 1.0 (OpenGL ES 2.0 Chromium)"],"ioBlackbox":null}}
        // Here is a sample fingerprint string from IOS: {"platform":"web","version":"2.0.0","attributes":{"browserName":"WebKit","browserVersion":"605.1.15","osName":"iOS","osVersion":"12.1.2","plugins":[],"platform":"iPhone","appVersion":"3000.7.5 (com.alkamitech.iccuqa1; build:10743) Mozilla/5.0 (iPhone; CPU iPhone OS 12_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/16C101 NATIVEAPP ORB NATIVEMENU","cssVendorPrefix":"webkit","cookiesEnabled":true,"javaEnabled":false,"flashEnabled":false,"flashVersion":"0.0.0","language":"en-US","doNotTrack":"unknown","timezoneOffset":-300,"width":375,"height":667,"availWidth":375,"availHeight":667,"colorDepth":32,"localStorage":true,"sessionStorage":true,"indexedDB":true,"fonts":[1,11,21,22,25,27,30,37,40,43,48,63,64,65,72,74,99,100,110,117,127,128,134,137,139,148,158,183,193,198,199,200,202,216,221,226,227,229,230,233,234,235,236,258,261,293,297,298,306,324,356,364,365,369,371,373,394,401,427,432,444,448,451,454,455,456,461,471,476,488,489,493],"canvas":"68ba9359e54939a9f42afd911348455624170828a4417834d4d058f4a5140cc3","webGL":["a1208499aca2742879fdb9fc517a4686ff899142d43b23785c36e50791630ed3","EXT_blend_minmax;EXT_sRGB;OES_texture_float;OES_texture_float_linear;OES_texture_half_float;OES_texture_half_float_linear;OES_standard_derivatives;EXT_shader_texture_lod;EXT_texture_filter_anisotropic;OES_vertex_array_object;OES_element_index_uint;WEBGL_lose_context;WEBKIT_WEBGL_compressed_texture_pvrtc;WEBGL_depth_texture;ANGLE_instanced_arrays;WEBGL_debug_renderer_info","(1 x 16)","(1 x 511)",8,true,8,24,8,16,8,4096,64,4096,8,4096,8,16,8,128,"(4096 x 4096)",8,"WebKit WebGL","WebGL GLSL ES 1.0 (OpenGL ES GLSL ES 1.00)",0,"WebKit","WebGL 1.0 (OpenGL ES 2.0 Metal - 58.4)"],"ioBlackbox":null}}
        /* tslint:enable */
        const fingerPrintObj = JSON.parse(fingerPrintStr);
        const webGL = fingerPrintObj.attributes.webGL;
        const intIndexes = [4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 24];
        for (let i = 0; i < webGL.length; i++) {
            if (webGL[i] == null && intIndexes.indexOf(i) > -1) {
                webGL[i] = 0;
            }
        }
        return JSON.stringify(fingerPrintObj);
    }
}
exports.EntrustMachineSecretProvider = EntrustMachineSecretProvider;
