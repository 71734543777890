"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupSiteText = exports.mockSiteTextMap = void 0;
/* eslint-disable @typescript-eslint/quotes */
const mockSiteTextMap = {
    "UsernameAndPassword.Header": "Welcome to Online Banking!",
    "UsernameAndPassword.TextField.Username.Label": "Username",
    "UsernameAndPassword.TextField.Username.Required": "Username is required",
    "UsernameAndPassword.TextField.Password.Label": "Password",
    "UsernameAndPassword.TextField.Password.Required": "Password is required",
    "UsernameAndPassword.Link.Forgot": "Forgot Username or Password?",
    "UsernameAndPassword.Link.Forgot.UsernameAndPassword": "Forgot {linku}Username{linku} or {linkp}Password{linkp}?",
    "UsernameAndPassword.Link.Forgot.Username": "{linku}Forgot Username{linku}?",
    "UsernameAndPassword.Link.Forgot.Password": "{linkp}Forgot Password{linkp}?",
    "UsernameAndPassword.Link.Forgot.Forgot": "Forgot",
    "UsernameAndPassword.Link.Forgot.Or": "or",
    "UsernameAndPassword.Link.Forgot.QuestionMark": "?",
    "UsernameAndPassword.Link.ForgotUsername": "Forgot Username",
    "UsernameAndPassword.Link.ForgotPassword": "Forgot Password",
    "UsernameAndPassword.Button.Login": "Login",
    "UsernameAndPassword.Button.Register": "Register a New Account",
    "DigitalAccountOpening.DisplayLink.Label": "Become a Member",
    "DigitalAccountOpening.DisplayLink.URL": "http://www.google.com",
    "UsernameAndPassword.Desktop.SideBar.Header": "On a mobile device?",
    "UsernameAndPassword.Desktop.SideBar.Text": "Download the app for convenient and secure access to your accounts.",
    "UsernameAndPassword.Desktop.SideBar.List.First.Icon": "touchid",
    "UsernameAndPassword.Desktop.SideBar.List.First.Header": "Biometric Login",
    "UsernameAndPassword.Desktop.SideBar.List.First.Text": "Use your device hardware",
    "UsernameAndPassword.Desktop.SideBar.List.Second.Icon": "atm",
    "UsernameAndPassword.Desktop.SideBar.List.Second.Header": "Nearby ATMs",
    "UsernameAndPassword.Desktop.SideBar.List.Second.Text": "Enable location services",
    "UsernameAndPassword.Desktop.SideBar.List.Third.Icon": "phone2",
    "UsernameAndPassword.Desktop.SideBar.List.Third.Header": "Snapshot",
    "UsernameAndPassword.Desktop.SideBar.List.Third.Text": "Preview accounts without login",
    "UsernameAndPassword.Desktop.SideBar.Link.ToMobile": "Go to mobile site",
    "StepUp.OneTimeCode.Header": "Enter Code",
    "StepUp.OneTimeCode.DisplayInput.ActionText": "Enter Code",
    "StepUp.OneTimeCode.DisplayInput.HelperText": "Code will expire in {0} minutes",
    "StepUp.OneTimeCode.Button.Verify": "Verify",
    "StepUp.Verification.Header": "Verification needed",
    "StepUp.Verification.HelperText": "Choose a verification method",
    "CodeStepup.Button.SendCode": "Send code",
    "MFA.Button.Navigate.Back": "Back",
    "RememberDevice.RememberThisDevice": "Remember this device",
    "RememberDevice.DeviceName": "Device name",
    "StepUp.Token.Button.Verify": "Verify",
    "StepUp.Token.DisplayInput.ActionText": "Enter code",
    "StepUp.TOTP.DisplayInput.HelperText": "Enter code shown in your two-factor authentication app",
    "StepUp.Token.DisplayInput.HelperText": "Enter code shown in your Entrust Token App",
    "CodeStepup.Destination.SendCodeTo": "Send code to",
    "CodeStepup.Verify.ResendCode": "Resend code",
    "CodeStepup.Verify.CodeSent": "Code sent",
    "CodeStepup.Verify.Phone.CodeSent": "A 6-digit code has been sent to your phone number ending in {0}",
    "CodeStepup.Verify.Email.CodeSent": "A 6-digit code has been sent to your email {0}",
    "Stepup.Icon.Label.SMS": "SMS",
    "Stepup.Icon.Label.EmailCode": "Email",
    "Stepup.Icon.Label.VoiceCode": "Call",
    "Stepup.Icon.Label.TOTP": "2FA",
    "Stepup.Icon.Label.Token": "Token",
    "Stepup.Icon.Label.Unknown": "Unknown",
    "ChallengeStatus.InvalidCredentials": "Invalid Credentials!",
    "ChallengeStatus.LockedOut": "Your account is locked. To reset it, click forgot password.",
    "ChallengeStatus.LockedOutinExternalIdentityProvider": "Your account is locked. Call the call-center for assistance with your account.",
    "ChallengeStatus.InvalidOneTimeCode": "Invalid code",
    "ChallengeStatus.InvalidCaptchaResponse": "Invalid CAPTCHA response",
    "ChallengeStatus.OTPExpired": "Your code has expired. Please request a new one.",
    "ChallengeStatus.DeclinedByRisk": "Your request has been declined.",
    "ChallengeStatus.SessionExpired": "Your session has expired.",
    "ChallengeStatus.TemporaryPasswordExpired": "Your temporary password has expired. To reset it, click forgot password.",
    "Error.Generic": "An unexpected error has occurred. Please try again later.",
    "AppStore.Link": "/appStore",
    "GooglePlay.Link": "/playStore",
    "Sync.Header": "Loading your accounts",
    "Sync.SyncError.Header": "Sync failed",
    "Sync.SyncError.Button.Back": "Login Sync Error Button",
    "ForgotPassword.SyncError.Button.Back": "ForgotPassword Sync Error Button",
    "Sync.SyncError.AEC.Unknown": "AEC.Unknown encountered",
    "Sync.SyncError.AEC.ProviderError": "AEC.ProviderError encountered",
    "Sync.SyncError.AEC.UnhandledProviderException": "AEC.UnhandledProviderException encountered",
    "Sync.SyncError.AEC.AccountTypeNotFound": "AEC.AccountTypeNotFound encountered",
    "Sync.SyncError.AEC.TimeOut": "AEC.TimeOut encountered",
    "Sync.SyncError.AEC.MemberIsNotEligibleToLogin": "AEC.MemberIsNotEligibleToLogin encountered",
    "Sync.SyncError.AEC.CacheExpired": "AEC.CacheExpired encountered",
    "Tethered.Loading": "Redirecting you to online banking...",
    "UsernameAndPassword.RememberUsername.Label": "Remember Me",
    "UsernameAndPassword.RememberUsername.Desktop.Message": "Do not select if you are using a public computer.",
    "UsernameAndPassword.InfoPanel.Ellipsis.IconName": "info",
    "UsernameAndPassword.InfoPanel.Ellipsis.Label": "Ellipsis Info Link",
    "UsernameAndPassword.InfoPanel.Header.Label": "Info Panel Header Label",
    "RememberDevice.Unavailable.Mandatory2fa": "Mandatory Two Factor Authentication has been enabled.",
    "ForgotWorkflow.Close.Confirm.Header": "Are you sure you want to quit?",
    "ForgotPassword.Close.Confirm.Text": "You will need to go through the forgot password flow again to reset password.",
    "ForgotPassword.Close.Text": "Cancel forgot password recovery",
    "ForgotUsername.Close.Confirm.Text": "You will need to go through the forgot username flow again to recover your username.",
    "ForgotUsername.Close.Text": "Cancel forgot username recovery",
    "ForgotWorkflow.Close.Confirm.Button.Text": "Yes",
    "ForgotWorkflow.Close.Cancel.Button.Text": "Cancel",
    "ForgotWorkflow.Disclosure.AcceptCheckbox.Label": "I Accept",
    "ForgotWorkflow.ActionButtonText": "Continue",
    "ForgotWorkflow.Disclosure.Mobile.Heading": "Please accept the disclosure",
    "ForgotUsername.ChooseScenario.Heading": "I want to",
    "ForgotUsername.ChooseScenario.Retail Forgot Username.Text": "Find My Personal Username",
    "ForgotUsername.ChooseScenario.Retail Forgot Username.Description": "Choose this if you are an individual and you forgot your username",
    "ForgotUsername.ChooseScenario.Business Forgot Username.Text": "Find My Business Username",
    "ForgotUsername.ChooseScenario.Business Forgot Username.Description": "Choose this if you are a business and you forgot your username",
    "ForgotUsername.Complete.Heading": "Your username is",
    "ForgotUsername.Complete.Button.Text": "Login",
    "ForgotPassword.ChooseScenario.Heading": "I want to",
    "ForgotPassword.ChooseScenario.Forgot Password.Text": "Reset My Password",
    "ForgotPassword.ChooseScenario.Forgot Password.Description": "Choose this if you're an individual or a business and forgot your password",
    "ForgotPassword.ChooseScenario.Business Sub User Forgot Password.Text": "Reset My Business Sub User Password",
    "ForgotPassword.ChooseScenario.Business Sub User Forgot Password.Description": "Choose this if you are a business sub user and you forgot your password",
    "ForgotUsername.Challenge.Heading": "Forgot Username",
    "ForgotUsername.ConfirmIdentity.Heading": "Verify your identity",
    "ForgotUsername.ConfirmIdentity.Notification": "",
    "ForgotUsername.UserIdentifyingFieldGroup.1.Text": "The following information is used to verify you have an account with us and that you are the owner of the account. We match your answers against our records.",
    "ForgotUsername.UserIdentifyingFieldGroup.2.Text": "Answer any one of the questions below",
    "ForgotPassword.Challenge.Heading": "Forgot Password",
    "ForgotPassword.ConfirmIdentity.Heading": "Verify your identity",
    "ForgotPassword.ConfirmIdentity.Notification": "",
    "ForgotPassword.UserIdentifyingFieldGroup.1.Text": "The following information is used to verify you have an account with us and that you are the owner of the account. We match your answers against our records.",
    "ForgotPassword.UserIdentifyingFieldGroup.2.Text": "Answer any one of the questions below",
    "UserIdentifyingField.Required": "Input is required",
    "UserIdentifyingField.ErrorMessage": "Invalid input",
    "UserIdentifyingField.Optional": "(Optional)",
    "UserIdentifyingField.LastName.Label": "Last Name",
    "UserIdentifyingField.LastName.HelperText": "",
    "UserIdentifyingField.EIN.Label": "EIN",
    "UserIdentifyingField.EIN.HelperText": "",
    "UserIdentifyingField.EIN.ErrorMessage": "",
    "UserIdentifyingField.AccountNumber.Label": "Account Number",
    "UserIdentifyingField.AccountNumber.HelperText": "",
    "UserIdentifyingField.AccountNumber.ErrorMessage": "",
    "UserIdentifyingField.DebitCardNumber.Label": "Debit Card Number",
    "UserIdentifyingField.DebitCardNumber.HelperText": "",
    "UserIdentifyingField.DebitCardNumber.ErrorMessage": "",
    "UserIdentifyingField.CreditCardNumber.Label": "Credit Card Number",
    "UserIdentifyingField.CreditCardNumber.HelperText": "",
    "UserIdentifyingField.CreditCardNumber.ErrorMessage": "",
    "UserIdentifyingField.Username.Label": "Confirm Username",
    "UserIdentifyingField.Username.HelperText": "",
    "UserIdentifyingField.TaxId.Label": "Social Security Number",
    "UserIdentifyingField.TaxId.HelperText": "",
    "UserIdentifyingField.TaxId.ErrorMessage": "Invalid {0}",
    "UserIdentifyingField.DateOfBirth.Label": "Date of Birth",
    "UserIdentifyingField.DateOfBirth.HelperText": "",
    "UserIdentifyingField.DateOfBirth.ErrorMessage": "Invalid {0}",
    "UserIdentifyingField.FirstName.Label": "First Name",
    "UserIdentifyingField.FirstName.HelperText": "",
    "UserIdentifyingField.MiddleName.Label": "Middle Name",
    "UserIdentifyingField.MiddleName.HelperText": "",
    "UserIdentifyingField.Suffix.Label": "Suffix",
    "UserIdentifyingField.Suffix.HelperText": "",
    "UserIdentifyingField.HomePhone.Label": "Home Phone",
    "UserIdentifyingField.HomePhone.HelperText": "",
    "UserIdentifyingField.WorkPhone.Label": "Work Phone",
    "UserIdentifyingField.WorkPhone.HelperText": "",
    "UserIdentifyingField.CellPhone.Label": "Cell Phone",
    "UserIdentifyingField.CellPhone.HelperText": "",
    "UserIdentifyingField.Address.Label": "Address",
    "UserIdentifyingField.Address.HelperText": "",
    "UserIdentifyingField.City.Label": "City",
    "UserIdentifyingField.City.HelperText": "",
    "UserIdentifyingField.State.Label": "State",
    "UserIdentifyingField.State.HelperText": "",
    "UserIdentifyingField.ZipCode.Label": "ZipCode",
    "UserIdentifyingField.ZipCode.HelperText": "",
    "UserIdentifyingField.PlaceOfBirth.Label": "Place of Birth",
    "UserIdentifyingField.PlaceOfBirth.HelperText": "",
    "UserIdentifyingField.MothersMaidenName.Label": "Mother's Maiden Name",
    "UserIdentifyingField.MothersMaidenName.HelperText": "",
    "UserIdentifyingField.AccountType.Label": "Account Type",
    "UserIdentifyingField.AccountType.HelperText": "",
    "UserIdentifyingField.IsBusiness.Label": "Is Business",
    "UserIdentifyingField.IsBusiness.HelperText": "",
    "UserIdentifyingField.Password.Label": "Password",
    "UserIdentifyingField.Password.HelperText": "",
    "UserIdentifyingField.None.Label": "None",
    "UserIdentifyingField.None.HelperText": "",
    "UserIdentifyingField.MemberNumber.Label": "Member Number",
    "UserIdentifyingField.MemberNumber.HelperText": "",
    "UserIdentifyingField.Email.Label": "Email",
    "UserIdentifyingField.Email.HelperText": "",
    "UserIdentifyingField.DriversLicenseNumber.Label": "Drivers License Number",
    "UserIdentifyingField.DriversLicenseNumber.HelperText": "",
    "UserIdentifyingField.CustomField.Label": "Custom Field",
    "UserIdentifyingField.CustomField.HelperText": "",
    "UserIdentifyingField.AccountHolderNumber.Label": "Account Holder Number",
    "UserIdentifyingField.AccountHolderNumber.HelperText": "",
    "UserIdentifyingField.Codeword.Label": "Codeword",
    "UserIdentifyingField.Codeword.HelperText": "",
    "UserIdentifyingField.PIN.Label": "PIN",
    "UserIdentifyingField.PIN.HelperText": "",
    "UserIdentifyingField.BusinessFirstName.Label": "Business First Name",
    "UserIdentifyingField.BusinessFirstName.HelperText": "",
    "UserIdentifyingField.BusinessLastName.Label": "Business Last Name",
    "UserIdentifyingField.BusinessLastName.HelperText": "",
    "UserIdentifyingField.BusinessName.Label": "Business Name",
    "UserIdentifyingField.BusinessName.HelperText": "",
    "ForgotPassword.StepUp.Heading": "Password reset method",
    "ForgotUsername.StepUp.Heading": "Username recovery method",
    "Forgot.SMS.Label": "SMS Text",
    "Forgot.SMS.Description": "A one-time code will be sent to your mobile phone.",
    "Forgot.SMS.Dropdown.Label": "Select Phone Number",
    "Forgot.Token.Label": "Token",
    "Forgot.Token.Description": "A one-time code generated through Entrust.",
    "Forgot.VoiceCall.Label": "Voice Call",
    "Forgot.VoiceCall.Description": "You will get a call that reads a one-time code to you.",
    "Forgot.VoiceCall.Dropdown.Label": "Select Phone Number",
    "Forgot.TOTP.Label": "Time-Based One Time Passcode",
    "Forgot.TOTP.Description": "A one-time passcode generated on your security application.",
    "Forgot.Email.Label": "Email",
    "Forgot.Email.Description": "A one-time code will be sent to your email address.",
    "Forgot.Email.Dropdown.Label": "Select Email",
    "ForgotPassword.VerificationCode.Heading": "Verification Code",
    "ForgotUsername.VerificationCode.Heading": "Verification Code",
    "Forgot.VerificationCode.SubHeading.Email": "A 6-digit code has been sent to your email.",
    "Forgot.VerificationCode.SubHeading.Token": "Enter the 8 digit code for your Token.",
    "Forgot.VerificationCode.SubHeading.Phone": "A 6-digit code has been sent to your phone number.",
    "Forgot.VerificationCode.SubHeading.TOTP": "Enter the 6 digit time-based one time passcode from your security application.",
    "Forgot.VerificationCode.EnterCode": "Enter Code",
    "Forgot.VerificationCode.HelperText.Expiration": "Code will expire in 5 minutes",
    "Forgot.VerificationCode.HelperText.Token": "Enter code shown in your Entrust token app",
    "Forgot.VerificationCode.HelperText.TOTP": "Enter code shown in your two - factor authentication app",
    "Forgot.VerificationCode.ResendCode": "Resend code",
    "Forgot.VerificationCode.CodeSent": "Code sent",
    "Forgot.VerificationCode.Or": "or",
    "Forgot.VerificationCode.ChangeMethod": "Change method",
    "Forgot.VerificationCode.Verify": "Verify",
    "Forgot.Progressbar.Heading": "Step {0} of {1}",
    "ForgotUsername.ChallengeStatus.UnableToLocateUser": "Unable to locate user, forgot username flow. Reference Code: 12345",
    "ForgotPassword.ChallengeStatus.UnableToLocateUser": "Unable to locate user, forgot password flow. Reference Code: 12345",
    "ForgotUsername.ChallengeStatus.MaximumAttemptsReached": "Username - Maximum number of lookup attempts reached.",
    "ForgotPassword.ChallengeStatus.MaximumAttemptsReached": "Password - Maximum number of lookup attempts reached.",
    "Registration.ChallengeStatus.MaximumAttemptsReached": "Registration - Maximum number of lookup attempts reached.",
    "Registration.ChooseScenario.Heading": "I want to",
    "Registration.ChooseScenario.Retail Registration.Text": "Register to my individual account",
    "Registration.ChooseScenario.Retail Registration.Description": "Select this if you have a personal account",
    "Registration.ChooseScenario.Business Registration.Text": "Register to my business account",
    "Registration.ChooseScenario.Business Registration.Description": "Select this if you have a business account",
    "Registration.ActionButtonText": "Next",
    "Registration.Disclosure.AcceptCheckbox.Label": "I agree",
    "Registration.ConfirmIdentity.Heading": "Confirm Your Identity",
    "Registration.ConfirmIdentity.Notification": "",
    "Registration.UserIdentifyingFieldGroup.1.Text": "The following information is used to verify you have an account with FI Central and that you are the owner of the account. We match your answers against our records.",
    "Registration.UserIdentifyingFieldGroup.2.Text": "Answer any one of the questions below",
    "Registration.Close.Confirm.Text": "You will need to go through the registration flow again to register your account",
    "Registration.Close.Text": "Cancel registration",
    "Registration.StepUp.Heading": "Verify Your Identity",
    "Registration.Progressbar.Heading": "Step {0} of {1}",
    "Registration.VerificationCode.Heading": "Verification Code",
    "Registration.ChallengeStatus.UnableToLocateUser": "We are unable to locate your account. The information you provided does not match out records. Please try again. Reference Code: 12345",
    "Registration.Disclosure.Heading": "Disclosure",
    "Registration.PDF.Heading": "PDF access confirmation",
    "Registration.PDF.Instructions": "Before you proceed, we need to make sure you can open PDF documents.",
    "Registration.PDF.OpenPDF": "Open PDF",
    "Registration.PDF.VerifyCode": "Copy the 5 character code into the text below.",
    "Registration.PDF.AccessCodeLabel": "Access Code",
    "Registration.PDF.Error": "We are unable to verify your PDF confirmation. Please use the Open PDF link to open the PDF and copy the provided code.",
    "Registration.Disclosure.HtmlBeforeRegistrationDisclosure": "",
    "Registration.Disclosure.HtmlBeforeBusinessRegistrationDisclosure": "",
    "Global.Disclosures.Registration.LabelFor.AgreementCheckbox": "I agree",
    "Registration.ButtonText.Continue": "Continue",
    "ChallengeStatus.Error": "An unexpected error has occurred. Please try again later.",
    "Registration.CreateUsername.Heading": "Create Username",
    "Registration.CreateUsername.SubHeading": "Create a unique, alphanumeric username between 6 and 10 characters in length.",
    "Registration.CreateUsername.Input.Label": "Username",
    "Registration.CreateUsername.Input.Unavailable": "Username unavailable",
    "Registration.CreateUsername.Input.Required": "This input field is required (English)",
    "Registration.CreateUsername.Input.Invalid": "Username is invalid",
    "Registration.CreateUsername.Input.InvalidLength": "Username must be between 6 and 30 characters in length.",
    "Registration.CreateUsername.Input.Available": "Username available",
    "Registration.CreateUsername.Suggestions": "Enter a new username or choose from the following options:",
    "Registration.CreateUsername.Suggestions.Exceeded": "You have exceeded your limit attempts.  Choose a username from the following:",
    "Registration.ButtonText.CreateUsername": "Create Username",
    "Registration.ChallengeStatus.GenericError": "An unexpected error has occurred. Please try again later.  Reference code: {0}",
    "Registration.ChallengeStatus.InsufficientContactInformation": "To log in to your account your contact information needs to be on file with your financial institution. Call the call-center to add your contact information.",
    "Registration.CreateUser.Heading": "Create Password",
    "Registration.CreateUser.Subheading": "Your password must be at least 8 characters long and contain at least one uppercase letter and one number.",
    "Registration.CreateUser.PasswordStrength.Weak": "Password strength is Weak",
    "Registration.CreateUser.PasswordStrength.Fair": "Password strength is Fair",
    "Registration.CreateUser.PasswordStrength.Strong": "Password strength is Strong",
    "Registration.CreateUser.PasswordMatches": "Password matches",
    "Registration.CreateUser.PasswordDoesNotMatch": "Password does not match",
    "Registration.CreateUser.InvalidPassword": "Password does not meet the requirements",
    "Registration.CreateUser.ButtonText": "Create Password",
    "Registration.CreateUser.TextField.Password.Label": "Password",
    "Registration.CreateUser.TextField.ConfirmPassword.Label": "Confirm password",
    "Registration.CreateUser.PasswordDoesNotMeetRequirements": "The password does not meet the requirements",
    "Registration.Input.Required": "This input field is required",
    "Registration.UpdateBusiness.Heading": "Business Information",
    "Registration.UpdateBusiness.Subheading": "Enter your business information to register your business account for online banking.",
    "Registration.UpdateBusiness.Input.BusinessName": "Business Name",
    "Registration.UpdateBusiness.Input.BusinessPhone": "Business Phone",
    "Registration.UpdateBusiness.Input.BusinessPhoneType": "Type of Number",
    "Registration.UpdateBusiness.Input.BusinessAddressLine1": "Business Address 1",
    "Registration.UpdateBusiness.Input.BusinessAddressLine2": "Business Address 2",
    "Registration.UpdateBusiness.Input.BusinessCity": "City",
    "Registration.UpdateBusiness.Input.BusinessState": "State",
    "Registration.UpdateBusiness.Input.BusinessZip": "Zip",
    "Registration.UpdateBusiness.Input.BusinessEin": "EIN",
    "Registration.UpdateBusiness.PhoneTypes.Home": "Home",
    "Registration.UpdateBusiness.PhoneTypes.Mobile": "Mobile",
    "Registration.UpdateBusiness.PhoneTypes.Work": "Work",
    "Registration.UpdateBusiness.Errors.Phone": "Enter a valid phone number with an area code.",
    "Registration.UpdateBusiness.Errors.Zip": "Enter a valid 5 digit or 9 digit zip code.",
    "Registration.UpdateBusiness.Errors.State": "Enter a 2 letter state code.",
    "Registration.UpdateBusiness.Errors.Ein": "Enter a valid 9 digit number.",
    "UsernameAndPassword.AppStore.NavigationPrompt.Title": "You are now leaving the Alpha Financial website",
    "UsernameAndPassword.AppStore.NavigationPrompt.Content": "The website you are accessing is not operated by Alpha Financial and we are not responsible for any content or information posted on this external website.  Please review their Terms, Privacy and Security policies to see how they apply to you.",
    "UsernameAndPassword.AppStore.NavigationPrompt.Continue": "Continue",
    "UsernameAndPassword.AppStore.NavigationPrompt.Cancel": "Cancel",
    "ConvertedUser.Header": "Secure your account",
    "ConvertedUser.Text": "{0} requires you to create a new password before you log in.",
    "ConvertedUser.Button.Primary": "Create new password",
    "ConvertedUser.Button.Secondary": "Return to login",
    "ChallengeStatus.MaximumAttemptsReached": "Login maximum attempts reached",
    "StepUp.OneTimeCode.Destination.SmsCode.Description": "Choose a phone number to verify.",
    "StepUp.OneTimeCode.Destination.VoiceCode.Description": "Choose a phone number to verify.",
    "StepUp.OneTimeCode.Destination.EmailCode.Description": "Choose an email to verify.",
    "UsernameAndPassword.Turnstile.Language": "tlh",
    "UsernameAndPassword.Error.WithReference": "An error has occurred.  Reference code: {0}",
    "ChallengeStatus.BotDetected": "Bot detected: {0}",
    "Tethered.Loading.AnimationAlt": "Alternative site text for the tethered loading animation",
    "Tethered.Loading.Button.Back": "Back to login",
};
exports.mockSiteTextMap = mockSiteTextMap;
function setupSiteText() {
    window.Alkami.Localization.SiteText.get = (key, params) => {
        let result = mockSiteTextMap[key];
        if (params && params.length > 0 && result) {
            for (let i = 0; i < params.length; i++) {
                result = result.replace('{' + i.toString() + '}', params[i]);
            }
        }
        if (result === undefined) {
            result = `__LOCALIZE_ME__${key}`;
        }
        return result;
    };
}
exports.setupSiteText = setupSiteText;
