"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Workflow = void 0;
var Workflow;
(function (Workflow) {
    Workflow["Authentication"] = "Authentication";
    Workflow["ForgotUsername"] = "ForgotUsername";
    Workflow["ForgotPassword"] = "ForgotPassword";
    Workflow["Registration"] = "Registration";
})(Workflow = exports.Workflow || (exports.Workflow = {}));
