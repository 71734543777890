"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const injectResources_1 = require("./injectResources");
const setupSiteText_1 = require("./setupSiteText");
const cssToInject = [
    'https://staging-iris.alkamitech.com/cdn/iris-vue/latest/iris-components.shim.desktop.min.css',
    'https://iris.alkamitech.com/cdn/iris-foundation/latest/iris-foundation.min.css',
    'https://staging-iris.alkamitech.com/cdn/iris-vue/latest/iris-components.min.css',
];
const jsToInject = [
    'https://staging-iris.alkamitech.com/cdn/iris-vue/latest/iris-vue.umd.min.js',
];
async function default_1() {
    if (window.Cypress) {
        (0, setupSiteText_1.setupSiteText)();
        await (0, injectResources_1.injectResources)({ jsToInject, cssToInject, injectLottie: true });
    }
}
exports.default = default_1;
