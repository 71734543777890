"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubCode = exports.Severity = exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["Unknown"] = 0] = "Unknown";
    ErrorCode[ErrorCode["Informational"] = 100] = "Informational";
    ErrorCode[ErrorCode["ValidationError"] = 200] = "ValidationError";
    ErrorCode[ErrorCode["DataRequestError"] = 300] = "DataRequestError";
    ErrorCode[ErrorCode["UnsupportedError"] = 310] = "UnsupportedError";
    ErrorCode[ErrorCode["PermissionError"] = 320] = "PermissionError";
    ErrorCode[ErrorCode["SystemNonFatalError"] = 400] = "SystemNonFatalError";
    ErrorCode[ErrorCode["SystemFatalError"] = 500] = "SystemFatalError";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
var Severity;
(function (Severity) {
    Severity[Severity["Warning"] = 0] = "Warning";
    Severity[Severity["Error"] = 1] = "Error";
    Severity[Severity["Fatal"] = 2] = "Fatal";
})(Severity = exports.Severity || (exports.Severity = {}));
var SubCode;
(function (SubCode) {
    SubCode[SubCode["None"] = 0] = "None";
    SubCode[SubCode["Custom"] = 1] = "Custom";
    SubCode[SubCode["NoDataInRequest"] = 300] = "NoDataInRequest";
    SubCode[SubCode["NoRecordsFound"] = 302] = "NoRecordsFound";
    SubCode[SubCode["BadRequest"] = 400] = "BadRequest";
    SubCode[SubCode["LoginInvalid"] = 401] = "LoginInvalid";
    SubCode[SubCode["SessionTokenExpired"] = 402] = "SessionTokenExpired";
    SubCode[SubCode["UserTokenExpired"] = 403] = "UserTokenExpired";
    SubCode[SubCode["SubCode409"] = 409] = "SubCode409";
    SubCode[SubCode["IncompleteRequest"] = 411] = "IncompleteRequest";
    SubCode[SubCode["PreviousRequestRequired"] = 412] = "PreviousRequestRequired";
    SubCode[SubCode["ValueOutOfRange"] = 413] = "ValueOutOfRange";
    SubCode[SubCode["ValueUnsupported"] = 415] = "ValueUnsupported";
    SubCode[SubCode["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    SubCode[SubCode["AccountLocked"] = 423] = "AccountLocked";
    SubCode[SubCode["MfaLoginFailed"] = 424] = "MfaLoginFailed";
    SubCode[SubCode["MacInvalid"] = 425] = "MacInvalid";
    SubCode[SubCode["EncryptionError"] = 426] = "EncryptionError";
    SubCode[SubCode["TooManyRequests"] = 429] = "TooManyRequests";
    SubCode[SubCode["InvalidLanguage"] = 430] = "InvalidLanguage";
    SubCode[SubCode["InvalidEmailFormat"] = 431] = "InvalidEmailFormat";
    SubCode[SubCode["InvalidPhoneFormat"] = 432] = "InvalidPhoneFormat";
    SubCode[SubCode["InvalidDataSourceId"] = 433] = "InvalidDataSourceId";
    SubCode[SubCode["InvalidFinancialInstitutionId"] = 434] = "InvalidFinancialInstitutionId";
    SubCode[SubCode["MalformedRequest"] = 435] = "MalformedRequest";
    SubCode[SubCode["AccessDenied"] = 436] = "AccessDenied";
    SubCode[SubCode["ArtifactNotFound"] = 437] = "ArtifactNotFound";
    SubCode[SubCode["UpdateToFieldNotAllowed"] = 438] = "UpdateToFieldNotAllowed";
    SubCode[SubCode["ArtifactCannotBeDecompressed"] = 439] = "ArtifactCannotBeDecompressed";
    SubCode[SubCode["MimeTypeNotSupported"] = 440] = "MimeTypeNotSupported";
    SubCode[SubCode["ArtifactArchived"] = 441] = "ArtifactArchived";
    SubCode[SubCode["ArtifactTooLarge"] = 442] = "ArtifactTooLarge";
    SubCode[SubCode["ArtifactTooSmall"] = 443] = "ArtifactTooSmall";
    SubCode[SubCode["ArtifactRejected"] = 444] = "ArtifactRejected";
    SubCode[SubCode["InvalidDataLength"] = 445] = "InvalidDataLength";
    SubCode[SubCode["InvalidFormat"] = 475] = "InvalidFormat";
    SubCode[SubCode["InvalidEnvironment"] = 476] = "InvalidEnvironment";
    SubCode[SubCode["DatesOutOfOrder"] = 497] = "DatesOutOfOrder";
    SubCode[SubCode["DateRangeTooLarge"] = 498] = "DateRangeTooLarge";
    SubCode[SubCode["GeneralError"] = 499] = "GeneralError";
    SubCode[SubCode["ServiceTemporarilyUnavailable"] = 503] = "ServiceTemporarilyUnavailable";
})(SubCode = exports.SubCode || (exports.SubCode = {}));
