"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultMachineSecretProvider = void 0;
class DefaultMachineSecretProvider {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initialize() { }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    write(map) { }
    read() {
        const map = {};
        map.director = this.hasPlugin('Shockwave');
        map.mimeTypes = this.getMimeTypes();
        map.mimeTypesCount = navigator.mimeTypes.length;
        map.plugins = this.getPlugins();
        map.pluginsCount = navigator.plugins.length;
        map.quickTime = this.hasPlugin('QuickTime');
        map.realPlayer = this.hasPlugin('RealPlayer');
        map.windowsMediaPlayer = this.hasPlugin('WindowsMediaPlayer');
        map.accrobatReader = this.hasPlugin('AdobeReader');
        map.svgViewer = false;
        map.clearType = false;
        map.screenColorDepth = screen.colorDepth;
        map.screenHeight = screen.height;
        map.screenPixelDepth = screen.pixelDepth;
        map.screenWidth = screen.width;
        map.screenAvailHeigth = screen.availHeight;
        map.screenAvailWidth = screen.availWidth;
        // map["screenBufferDepth"] = screen.bufferDepth;
        map.appName = navigator.appName;
        map.appVersion = navigator.appVersion;
        map.appMinorVersion = navigator.appMinorVersion;
        map.cookieEnabled = navigator.cookieEnabled;
        map.cpuClass = navigator.cpuClass;
        map.systemLanguage = navigator.systemLanguage;
        map.userAgent = navigator.userAgent;
        map.userLanguage = navigator.userLanguage;
        map.javaEnabled = navigator.javaEnabled();
        map.platform = navigator.platform;
        map.appCodeName = navigator.appCodeName;
        map.language = navigator.language;
        map.oscpu = navigator.oscpu;
        map.vendor = navigator.vendor;
        map.vendorSub = navigator.vendorSub;
        map.product = navigator.product;
        map.productSub = navigator.productSub;
        if (typeof navigator.taintEnabled !== 'undefined') {
            map.taintEnabled = navigator.taintEnabled();
        }
        return map;
    }
    getMimeTypes() {
        if (navigator.mimeTypes && navigator.mimeTypes.length) {
            return Array.from(navigator.mimeTypes)
                .map((mimeType) => mimeType.type)
                .join(',');
        }
        return '';
    }
    getPlugins() {
        if (navigator.plugins && navigator.plugins.length) {
            return Array.from(navigator.plugins)
                .map((plugin) => plugin.name.replace(/&/g, ''))
                .join(',');
        }
        return '';
    }
    hasPlugin(pluginName) {
        const PluginDetect = window.PluginDetect;
        if (PluginDetect) {
            return PluginDetect.getVersion(pluginName) !== null;
        }
        return false;
    }
}
exports.DefaultMachineSecretProvider = DefaultMachineSecretProvider;
