"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementIds = void 0;
/**
 * Enum for Element IDs that are referenced by other systems or services - for analytics, security, or any other purpose.
 * These IDs stored here to remain constant, and should not be updated unless also updated by the consuming system or service.
 *
 * @readonly
 * @enum {string}
 */
var ElementIds;
(function (ElementIds) {
    ElementIds["Username"] = "username";
    ElementIds["Password"] = "password";
    ElementIds["AuthenticationSubmit"] = "btn_submitCredentials";
    ElementIds["DisclosureCheckbox"] = "acceptDisclosureCheckbox";
    ElementIds["DisclosureContinue"] = "disclosureAcceptanceBtn";
    ElementIds["UsernameChooseScenarioCard"] = "usernameType";
    ElementIds["UserIdentifyingField"] = "UserIdentifyingField";
    ElementIds["ConfirmIdentityContinue"] = "btn_submitConfirmIdentity";
    ElementIds["StepUpCategory"] = "stepUpCategory";
    ElementIds["StepUpDestination"] = "stepUpDestination";
    ElementIds["StepUpContinue"] = "btn_chooseStepUpOptions";
    ElementIds["OTPCodeInput"] = "codeInput";
    ElementIds["OTPChangeMethod"] = "btn_changeMethod";
    ElementIds["OTPResendCode"] = "btn_resendOTP";
    ElementIds["OTPVerify"] = "btn_submitOTPCode";
    ElementIds["CreateUsername"] = "btn_createUsername";
    ElementIds["UpdateBusiness"] = "btn_updateBusiness";
})(ElementIds = exports.ElementIds || (exports.ElementIds = {}));
