"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICustomTelemetryEvent = exports.userAction = exports.stepName = exports.stepType = exports.workflowName = exports.getWorkflowName = exports.getStepName = exports.telemetry = void 0;
const ChallengeType_1 = require("../enums/ChallengeType");
const Workflow_1 = require("../enums/Workflow");
const UserTelemetry_1 = require("../enums/UserTelemetry");
Object.defineProperty(exports, "workflowName", { enumerable: true, get: function () { return UserTelemetry_1.workflowName; } });
Object.defineProperty(exports, "userAction", { enumerable: true, get: function () { return UserTelemetry_1.userAction; } });
Object.defineProperty(exports, "stepType", { enumerable: true, get: function () { return UserTelemetry_1.stepType; } });
Object.defineProperty(exports, "stepName", { enumerable: true, get: function () { return UserTelemetry_1.stepName; } });
const IUserTelemetry_1 = require("../interfaces/IUserTelemetry");
Object.defineProperty(exports, "ICustomTelemetryEvent", { enumerable: true, get: function () { return IUserTelemetry_1.ICustomTelemetryEvent; } });
class _userTelemetry {
    constructor() {
        this.status = {
            enabled: false,
            retries: 10,
            delay: 100,
            cachedEvents: new Array(),
        };
        this.requests = new Map();
        this.checkTelemetry = async () => {
            var _a, _b;
            if (((_a = window.Alkami) === null || _a === void 0 ? void 0 : _a.UserTelemetry) !== null && ((_b = window.Alkami) === null || _b === void 0 ? void 0 : _b.UserTelemetry) !== undefined) {
                this.status.enabled = true;
                // send cached events
                if (this.status.cachedEvents.length > 0) {
                    for (const event of this.status.cachedEvents) {
                        switch (event.type) {
                            case 'page':
                                await this.page(event.event);
                                break;
                            case 'custom':
                                await this.custom(event.event);
                                break;
                            case 'identify':
                                await this.identify();
                                break;
                        }
                    }
                    this.status.cachedEvents = [];
                }
                return;
            }
            // Retries exhausted - telemetry not enabled
            if (--this.status.retries <= 0) {
                this.status.cachedEvents = [];
                return;
            }
            setTimeout(() => { void this.checkTelemetry(); }, this.status.delay);
        };
        this.page = async (workflow) => {
            var _a;
            if (this.enabled({ type: 'page', event: workflow }) === false || this.isDupe(`${workflow}|page`))
                return;
            await ((_a = window.Alkami.UserTelemetry) === null || _a === void 0 ? void 0 : _a.logPageEventAsync(workflow));
        };
        this.custom = async (event) => {
            var _a, _b;
            if (this.enabled({ type: 'custom', event }) === false || this.isDupe(`${event.workflowName}|custom|${event.action}`))
                return;
            event.eventProperties = (_a = event.eventProperties) !== null && _a !== void 0 ? _a : {};
            event.eventProperties.stepType = event.stepType;
            event.eventProperties.stepName = event.stepName;
            await ((_b = window.Alkami.UserTelemetry) === null || _b === void 0 ? void 0 : _b.logCustomEventAsync(event.workflowName, event.action, event.eventProperties));
        };
        this.identify = async () => {
            var _a;
            if (this.enabled({ type: 'identify' }) === false || this.isDupe('identify'))
                return;
            await ((_a = window.Alkami.UserTelemetry) === null || _a === void 0 ? void 0 : _a.identifyAuthenticatedUserAsync());
        };
        this.timeout = async (ms) => {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            });
        };
        this.isDupe = (event) => {
            const now = Date.now();
            const prev = this.requests.get(event);
            // reject duplicate events that are less than 50ms apart
            if (prev != null && now - prev < 50) {
                return true;
            }
            this.requests.set(event, now);
            // keep the map from getting too big
            if (this.requests.size > 50) {
                const t = new Map();
                this.requests.forEach((value, key) => {
                    if (now - value < 50)
                        t.set(key, value);
                });
                this.requests = t;
            }
            return false;
        };
        void this.checkTelemetry();
    }
    static get factory() {
        if (!(userTelemetryObj instanceof _userTelemetry))
            userTelemetryObj = new _userTelemetry();
        return userTelemetryObj;
    }
    get context() {
        var _a;
        return (_a = window.Alkami.UserTelemetry) === null || _a === void 0 ? void 0 : _a.context;
    }
    enabled(event) {
        if (this.status.enabled === true)
            return true;
        if (this.status.retries > 0)
            this.status.cachedEvents.push(event);
        return false;
    }
}
_userTelemetry.getStepName = (workflow, challenge) => {
    // for now only the retail_registration workflow exists
    if (workflow === UserTelemetry_1.workflowName.retail_registration) {
        switch (challenge) {
            case ChallengeType_1.ChallengeType.ChooseRegistrationType:
                return UserTelemetry_1.stepName.registration_type;
            case ChallengeType_1.ChallengeType.RegistrationDisclosure:
                return UserTelemetry_1.stepName.disclosure;
            case ChallengeType_1.ChallengeType.ConfirmIdentity:
                return UserTelemetry_1.stepName.confirm_your_identity;
            case ChallengeType_1.ChallengeType.StepUp:
                return UserTelemetry_1.stepName.verify_your_identity;
            case ChallengeType_1.ChallengeType.OneTimeCode:
                return UserTelemetry_1.stepName.verification_code;
            case ChallengeType_1.ChallengeType.CreateUsername:
                return UserTelemetry_1.stepName.create_username;
            case ChallengeType_1.ChallengeType.CreateUser:
                return UserTelemetry_1.stepName.create_password;
            case ChallengeType_1.ChallengeType.UpdateBusiness:
                return UserTelemetry_1.stepName.business_information;
        }
    }
    return UserTelemetry_1.stepName.unknown;
};
_userTelemetry.getWorkflowName = (workflow) => {
    // currently only the registration workflow exists
    switch (workflow) {
        case Workflow_1.Workflow.Registration:
            return UserTelemetry_1.workflowName.retail_registration;
    }
    return UserTelemetry_1.workflowName.unknown;
};
let userTelemetryObj;
const telemetry = _userTelemetry.factory;
exports.telemetry = telemetry;
const getStepName = _userTelemetry.getStepName;
exports.getStepName = getStepName;
const getWorkflowName = _userTelemetry.getWorkflowName;
exports.getWorkflowName = getWorkflowName;
