"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const MachineSecretProviderType_1 = require("../enums/MachineSecretProviderType");
const EntrustMachineSecretProvider_1 = require("./EntrustMachineSecretProvider");
const DefaultMachineSecretProvider_1 = require("./DefaultMachineSecretProvider");
class MachineSecretProviderManager {
    constructor() {
        this.provider = new DefaultMachineSecretProvider_1.DefaultMachineSecretProvider();
    }
    createProvider(machineSecretProviderType) {
        let machineSecretProvider;
        switch (machineSecretProviderType) {
            case MachineSecretProviderType_1.MachineSecretProviderType.EntrustRememberedDevicesProvider:
                machineSecretProvider = new EntrustMachineSecretProvider_1.EntrustMachineSecretProvider();
                break;
            default:
                machineSecretProvider = new DefaultMachineSecretProvider_1.DefaultMachineSecretProvider();
        }
        this.provider = machineSecretProvider;
        return machineSecretProvider;
    }
    get machineSecretProvider() {
        return this.provider;
    }
}
exports.default = new MachineSecretProviderManager();
