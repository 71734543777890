"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScenarioType = void 0;
var ScenarioType;
(function (ScenarioType) {
    ScenarioType[ScenarioType["Unknown"] = 0] = "Unknown";
    ScenarioType[ScenarioType["Registration"] = 1] = "Registration";
    ScenarioType[ScenarioType["AdminRegistration"] = 2] = "AdminRegistration";
    ScenarioType[ScenarioType["ForgotPassword"] = 3] = "ForgotPassword";
    ScenarioType[ScenarioType["ForgotUsername"] = 4] = "ForgotUsername";
    ScenarioType[ScenarioType["UserSearch"] = 5] = "UserSearch";
})(ScenarioType = exports.ScenarioType || (exports.ScenarioType = {}));
