"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userAction = exports.stepName = exports.stepType = exports.workflowName = void 0;
var workflowName;
(function (workflowName) {
    workflowName["unknown"] = "unknown";
    workflowName["retail_registration"] = "retail_registration";
})(workflowName = exports.workflowName || (exports.workflowName = {}));
var stepType;
(function (stepType) {
    stepType["unknown"] = "unknown";
    stepType["entry"] = "entry";
    stepType["progression"] = "progression";
    stepType["cancellation"] = "cancellation";
})(stepType = exports.stepType || (exports.stepType = {}));
var stepName;
(function (stepName) {
    stepName["unknown"] = "unknown";
    stepName["login"] = "login";
    stepName["registration_type"] = "registration_type";
    stepName["disclosure"] = "disclosure";
    stepName["confirm_your_identity"] = "confirm_your_identity";
    stepName["verify_your_identity"] = "verify_your_identity";
    stepName["verification_code"] = "verification_code";
    stepName["business_information"] = "business_information";
    stepName["create_username"] = "create_username";
    stepName["create_password"] = "create_password";
    stepName["confirm_contact"] = "confirm_contact";
    stepName["quit_confirmation"] = "quit_confirmation";
})(stepName = exports.stepName || (exports.stepName = {}));
var userAction;
(function (userAction) {
    userAction["unknown"] = "unknown";
    userAction["overflow_menu_clicked"] = "overflow_menu_clicked";
    userAction["password_show_toggled"] = "password_show_toggled";
    userAction["remember_me_checked"] = "remember_me_checked";
    userAction["forgot_username_clicked"] = "forgot_username_clicked";
    userAction["forgot_password_clicked"] = "forgot_password_clicked";
    userAction["register_account_clicked"] = "register_account_clicked";
    userAction["cancel_clicked"] = "cancel_clicked";
    userAction["individual_account_clicked"] = "individual_account_clicked";
    userAction["business_account_clicked"] = "business_account_clicked";
    userAction["new_account_selected"] = "new_account_selected";
    userAction["continue_clicked"] = "continue_clicked";
    userAction["agree_clicked"] = "agree_clicked";
    userAction["open_pdf_clicked"] = "open_pdf_clicked";
    userAction["print_pdf_clicked"] = "print_pdf_clicked";
    userAction["disclosure_scroll"] = "disclosure_scroll";
    userAction["verification_type_chosen"] = "verification_type_chosen";
    userAction["resend_code_clicked"] = "resend_code_clicked";
    userAction["change_method_clicked"] = "change_method_clicked";
    userAction["create_username_clicked"] = "create_username_clicked";
    userAction["create_password_clicked"] = "create_password_clicked";
    userAction["confirm_clicked"] = "confirm_clicked";
    userAction["estatements_accept_checked"] = "estatements_accept_checked";
    userAction["email_preferences_checked"] = "email_preferences_checked";
    userAction["subscribe_pfm_clicked"] = "subscribe_pfm_clicked";
    userAction["yes_clicked"] = "yes_clicked";
    userAction["sheet_closed"] = "sheet_closed";
})(userAction = exports.userAction || (exports.userAction = {}));
