"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSiteTextItem = void 0;
function getSiteTextItem(key, options = {}) {
    const { debug = false, replacements = [], siteTextMap = {} } = options;
    let value = siteTextMap[key];
    if (debug) {
        if (value === null || value === undefined) {
            key = `__Localize_Me__${key}`;
        }
        return [key, ...replacements].join(' ');
    }
    if (value === null || value === undefined) {
        return `__Localize_Me__${key}`;
    }
    if (replacements.length > 0) {
        value = value.replace(/{(\d+)}/g, (match, index) => {
            if (typeof replacements[index] !== 'undefined') {
                return replacements[index].toString();
            }
            return match;
        });
    }
    return value;
}
exports.getSiteTextItem = getSiteTextItem;
