"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserIdentifyingFieldType = void 0;
var UserIdentifyingFieldType;
(function (UserIdentifyingFieldType) {
    UserIdentifyingFieldType[UserIdentifyingFieldType["FirstName"] = 0] = "FirstName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["LastName"] = 1] = "LastName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["TaxId"] = 4] = "TaxId";
    UserIdentifyingFieldType[UserIdentifyingFieldType["HomePhone"] = 5] = "HomePhone";
    UserIdentifyingFieldType[UserIdentifyingFieldType["WorkPhone"] = 6] = "WorkPhone";
    UserIdentifyingFieldType[UserIdentifyingFieldType["CellPhone"] = 7] = "CellPhone";
    UserIdentifyingFieldType[UserIdentifyingFieldType["Address"] = 8] = "Address";
    UserIdentifyingFieldType[UserIdentifyingFieldType["City"] = 9] = "City";
    UserIdentifyingFieldType[UserIdentifyingFieldType["State"] = 10] = "State";
    UserIdentifyingFieldType[UserIdentifyingFieldType["ZipCode"] = 11] = "ZipCode";
    UserIdentifyingFieldType[UserIdentifyingFieldType["DateOfBirth"] = 12] = "DateOfBirth";
    UserIdentifyingFieldType[UserIdentifyingFieldType["MothersMaidenName"] = 14] = "MothersMaidenName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["AccountNumber"] = 15] = "AccountNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["IsBusiness"] = 17] = "IsBusiness";
    UserIdentifyingFieldType[UserIdentifyingFieldType["BusinessName"] = 18] = "BusinessName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["Password"] = 19] = "Password";
    UserIdentifyingFieldType[UserIdentifyingFieldType["MemberNumber"] = 21] = "MemberNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["Email"] = 22] = "Email";
    UserIdentifyingFieldType[UserIdentifyingFieldType["DriversLicenseNumber"] = 23] = "DriversLicenseNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["CustomField"] = 24] = "CustomField";
    UserIdentifyingFieldType[UserIdentifyingFieldType["DebitCardNumber"] = 25] = "DebitCardNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["CreditCardNumber"] = 26] = "CreditCardNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["AccountHolderNumber"] = 27] = "AccountHolderNumber";
    UserIdentifyingFieldType[UserIdentifyingFieldType["Codeword"] = 28] = "Codeword";
    UserIdentifyingFieldType[UserIdentifyingFieldType["PIN"] = 29] = "PIN";
    UserIdentifyingFieldType[UserIdentifyingFieldType["EIN"] = 30] = "EIN";
    UserIdentifyingFieldType[UserIdentifyingFieldType["Username"] = 31] = "Username";
    UserIdentifyingFieldType[UserIdentifyingFieldType["BusinessFirstName"] = 32] = "BusinessFirstName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["BusinessLastName"] = 33] = "BusinessLastName";
    UserIdentifyingFieldType[UserIdentifyingFieldType["ConfigurableField1"] = 34] = "ConfigurableField1";
    UserIdentifyingFieldType[UserIdentifyingFieldType["ConfigurableField2"] = 35] = "ConfigurableField2";
})(UserIdentifyingFieldType = exports.UserIdentifyingFieldType || (exports.UserIdentifyingFieldType = {}));
