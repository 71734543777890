"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationFactor = void 0;
var AuthenticationFactor;
(function (AuthenticationFactor) {
    AuthenticationFactor["Unknown"] = "Unknown";
    AuthenticationFactor["SmsCode"] = "SmsCode";
    AuthenticationFactor["EmailCode"] = "EmailCode";
    AuthenticationFactor["VoiceCode"] = "VoiceCode";
    AuthenticationFactor["TOTP"] = "TOTP";
    AuthenticationFactor["WebAuthn"] = "WebAuthn";
    AuthenticationFactor["PushNotification"] = "PushNotification";
    AuthenticationFactor["VoicePrompt"] = "VoicePrompt";
    AuthenticationFactor["QuestionsAndAnswers"] = "QuestionsAndAnswers";
    AuthenticationFactor["Token"] = "Token";
    AuthenticationFactor["Biometric"] = "Biometric";
})(AuthenticationFactor = exports.AuthenticationFactor || (exports.AuthenticationFactor = {}));
