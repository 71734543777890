<template>
    <one-time-code />
</template>

<script>
import Vue from 'vue';
import OneTimeCode from './OneTimeCode.vue';
export default Vue.extend({
    name: 'one-time-code-destination',
    components: {
        OneTimeCode,
    },
});
</script>
